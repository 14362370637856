<template>
  <FormInput
    :value="propertyGetter"
    :label="$t(label)"
    :helper="helper"
    @input="propertySetter"
  />
</template>

<script>
import highlightedNode from '~/mixins/highlightedNode'

export default {
  mixins: [highlightedNode],
  props: ['value', 'label', 'helper', 'property'],
  data() {
    return {}
  },
  computed: {
    /**
     * Get the value of the edited property
     */
    propertyGetter() {
      const value = _.get(this.highlightedNodeDefinition, this.property)
      return value
    }
  },
  methods: {
    /**
     * Update the value of the editer property
     */
    propertySetter(value) {
      _.set(this.highlightedNodeDefinition, this.property, value)
      this.$emit('input', this.value)
    }
  }
}
</script>
