import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields(['highlightedNodes']),
    highlightedNode() {
      return this.highlightedNodes[0]
    },
    highlightedNodeDefinition() {
      return this.highlightedNode.definition
    }
  }
}
