import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'
import component from './messageEndEvent.vue'
import endEventConfig from '~/components/nodes/endEvent'
import CatchEventHookSelect from '../intermediateMessageCatchEvent/CatchEventHookSelect'


export default merge(cloneDeep(endEventConfig), {
  id: 'solyd-modeler-message-end-event',
  component,
  control: false,
  label: 'Hook End Event',
  definition(moddle, $t) {
    return moddle.create('bpmn:EndEvent', {
      name: $t('Hook End Event'),
      eventDefinitions: [moddle.create('bpmn:MessageEventDefinition')]
    })
  },
  inspectorData(node) {
    return Object.entries(node.definition).reduce((data, [key, value]) => {
      if (key === 'eventDefinitions') {
        data.messageName = value[0].get('messageRef').name
      } else {
        data[key] = value
      }

      return data
    }, {})
  },
  inspectorHandler(value, node, setNodeProp) {
    for (const key in omit(value, [
      '$type',
      'eventDefinitions',
      'messageName'
    ])) {
      if (node.definition[key] === value[key]) {
        continue
      }

      setNodeProp(node, key, value[key])
    }

    const message = node.definition.get('eventDefinitions')[0].messageRef
    if (message.name !== value.messageName) {
      message.name = value.messageName
    }
  },
  inspectorConfig: [
    {
      items: [
        {
          items: [
            {},
            {
              component: CatchEventHookSelect,
              config: {
                label: 'Send to Hook',
                name: 'messageName',
                helper:
                  'Select to which Intermediate Message Throw or Message End event to emit'
              }
            }
          ]
        }
      ]
    }
  ]
})
