<template>
  <div class="form-group">
    <label v-if="label">{{ label }}</label>
    <multi-select
      v-model="content"
      track-by="id"
      label="fullname"
      group-values="items"
      group-label="type"
      :class="{ 'border border-danger': error }"
      :loading="loading"
      :placeholder="$t('type here to search')"
      :options="options"
      :multiple="multiple"
      :show-labels="false"
      :searchable="true"
      :internal-search="false"
      @open="load"
      @search-change="load"
    >
      <template slot="noResult">
        <slot name="noResult">{{
          $t('No elements found. Consider changing the search query.')
        }}</slot>
      </template>
      <template slot="noOptions">
        <slot name="noOptions">{{ $t('No Data Available') }}</slot>
      </template>
    </multi-select>

    <small v-if="error" class="text-danger">{{ error }}</small>
    <small v-if="helper" class="form-text text-muted">{{ $t(helper) }}</small>
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect'
export default {
  components: {
    MultiSelect
  },
  props: {
    value: null,
    label: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: true
    },
    hideUsers: {
      type: Boolean,
      default: false
    },
    hideGroups: {
      type: Boolean,
      default: false
    },
    error: String,
    helper: String
  },
  data() {
    return {
      loading: false,
      selected: {
        users: [],
        groups: []
      },
      options: [],
      results: [],
      lastEmitted: '',
      labelUsers: this.$t('Users'),
      labelGroups: this.$t('Groups'),
      labelAssets: this.$t('Assets'),
    }
  },
  computed: {
    content: {
      get() {
        if (this.loading) {
          return []
        }
        return this.selected.users
          .map((uid) => {
            console.log(">7>", uid)
            return this.results.find((item) => item.uid === uid)
          })
          .concat(
            this.selected.groups.map((gid) => {
              console.log(">8>", gid)
              return this.results.find((item) => item.id === gid)
            })
          )
      },
      set(value) {
        this.selected.users = []
        this.selected.groups = []
        value.forEach((item) => {
          this.results.push(item)
          //console.log(">9>", item, 'uid' in item, 'id' in item)
          if ('uid' in item) {
            console.log(">10>", item)
            this.selected.users.push(item.uid)
          } else {
            //console.log(">5>", item)
            this.selected.groups.push(item.id) //parseInt(item.id.substr(6)))
          }
        })
      }
    }
  },
  watch: {
    content: {
      handler() {
        this.lastEmitted = JSON.stringify(this.selected)
        console.log(">>>1:",this.lastEmitted,">2:",this.selected,">3:", this.content )

        this.$emit('input', this.selected)
      }
    },
    value: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        if (value.users.length === 0 && value.groups.length === 0) {
          return
        }
        if (JSON.stringify(value) === this.lastEmitted) {
          return
        }
        this.loading = true
        const results = []
        console.log(">0>", value)
        const usersPromise = Promise.all(
          value.users.map((item) => {
            console.log(">1>: ", item)
            return this.$axiosApi.$get('data/getmember?id=' + item.replace("user-",""))
          })
        ).then((items) => {
          items.forEach((item) => {
            results.push(item.opt)
          })
        })

        const groupsPromise = Promise.all(
          value.groups.map((item) => {
            if(item.includes('group-')){
              console.log("Group: ", item, item.includes('group-'))
              return this.$axiosApi.$get('data/getteam?d=3&id=' + item.replace("group-","") )
            }else
              return this.$axiosApi.$get('data/getassetbyid?id=' + item.replace("asset-","") )
          })
        ).then((items) => {
          if(items){
            console.log("ITEMS: ", items)

            items.forEach((item) => {
              if("asset" in item.opt)
                results.push(this.formatAsset(item.opt))
              else
                results.push(this.formatGroup(item.opt))
            })
          }

        })

        Promise.all([usersPromise, groupsPromise]).then(() => {
          this.content = results
          this.loading = false
        })
      }
    }
  },
  methods: {
    load(filter) {
      this.options = []
      if (!this.hideUsers) {
        this.loadUsers(filter)
      }
      if (!this.hideGroups) {
        this.loadGroups(filter)
      }
    },
    loadUsers(filter) {
      this.$axiosApi
        .$get('data/getmembers' + (typeof filter === 'string' ? '?filter=' + filter : ''))
        .then((response) => {
          this.users = response.opts
          if (response.opts) {
            this.options.push({
              type: this.labelUsers,
              items: response.opts
            })
          }
        })
    },
    loadGroups(filter) {
      this.$axiosApi
        .$get(
          'data/getteams' + (typeof filter === 'string' ? '?filter=' + filter : '')
        )
        .then((response) => {
          const groups = response.teams.map((item) => {

            return this.formatGroup(item)
          })

          const assets = response.assets.map((item) => {

            return this.formatAsset(item)
          })

          if (groups) {
            this.options.push({
              type: this.labelGroups,
              items: groups
            })
          }

          if (assets) {
            this.options.push({
              type: this.labelAssets,
              items: assets
            })
          }

        })
    },
    formatGroup(item) {
      //console.log(">4>", item)
      item.id = 'group-' + item.id
      item.fullname = "Group/"+item.name
      return item
    },
    formatAsset(item) {
      //console.log(">4>", item)
      item.id = 'asset-' + item.id
      item.fullname =  "Asset/"+item.name
      return item
    }
  }
}
</script>
