<template>
  <crown-button
    v-if="validMessageFlowSources.includes(node.type)"
    id="message-flow-button"
    v-b-tooltip.hover.viewport.d50="{ customClass: 'no-pointer-events' }"
    :title="$t('Message Flow')"
    aria-label="Create a message flow"
    :src="messageFlow"
    role="menuitem"
    @click="addMessageFlow"
  />
</template>
<script>
import messageFlow from '~/assets/message-flow.svg'
import CrownButton from '~/components/crown/crownButtons/crownButton'
import Node from '~/components/nodes/node'

export default {
  components: { CrownButton },
  props: ['node', 'moddle', 'shape'],
  data() {
    return {
      messageFlow,
      validMessageFlowSources: [
        'solyd-modeler-start-event',
        'solyd-modeler-end-event',
        'solyd-modeler-task',
        'solyd-modeler-pool',
        'solyd-modeler-intermediate-message-throw-event'
      ]
    }
  },
  methods: {
    addMessageFlow(cellView, evt, x, y) {
      this.$emit('toggle-crown-state', false)

      const messageFlowDefinition = this.moddle.create('bpmn:MessageFlow', {
        name: '',
        sourceRef: this.shape.component.node.definition,
        targetRef: { x, y }
      })

      this.$emit(
        'add-node',
        new Node(
          'solyd-modeler-message-flow',
          messageFlowDefinition,
          this.moddle.create('bpmndi:BPMNEdge')
        )
      )
    }
  }
}
</script>
