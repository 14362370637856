export default {
  items: [
    {
      name: 'Title1',
      title: 'Title2',
      id:'toplabel',
      url: '#queue',
      icon: 'icon-speedometer',
      badge: {
        variant: 'primary'
        // text: 'NEW'
      }
    },
    {
      divider: true
    },
    {
      name: 'Queue',
      id:'queue',
      url: '#queue',
      icon: 'icon-speedometer',
      badge: {
        variant: 'primary'
        // text: 'NEW'
      }
    },
    {
      divider: true
    },
    {
      name: 'Alerts',
      id:'alerts',
      url: '#alert',
      icon: 'icon-bell'
    },

    {
      divider: true
    },
    {
      name: 'Start',
      id:'start',
      url: '#start',
      icon: 'icon-control-play',
      badge: {
        variant: 'primary'
        // text: 'NEW'
      }
    },
  ]
}
