export default {
  items: [
    {
      //title: true,
      name: 'Home',
      icon: 'icon-home',
      class: '',
      url: '/',
      id: "home",
      wrapper: {
        element: '',
        attributes: {}
      }
    },
    {
      name: 'Wallets & Dapps',
      url: '/walletNdapps',
      icon: 'icon-wallet',
      id: "walletndapps",
      badge: {
        variant: 'primary'
        // text: 'NEW'
      }
    },
    {
      title: true,
      name: 'Business',
      class: '',
      id: "business",
      wrapper: {
        element: '',
        attributes: {}
      }
    },
    {
      name: 'Operations',
      url: '/business',
      icon: 'icon-graph',
      id: "operations",
      badge: {
        variant: 'primary',
         text: '*'
      }
    },
    {
      title: true,
      name: 'Admin',
      class: '',
      id: "admin",
      wrapper: {
        element: '',
        attributes: {}
      }
    },
    {
      name: 'Frontend',
      url: '/admin/',
      icon: 'icon-layers',
      id: "adminfrontend",
      badge: {
        variant: 'primary',
        text: '*'
      }
    },
    {
      name: 'APIs & Hooks',
      url: '/admin/apihooks',
      icon: 'icon-vector',
      id: "apiandhooks",
      badge: {
        variant: 'primary',
        text: '*'
      }
    },
    {
      name: 'Blockchain',
      url: '/admin/blockchain',
      icon: 'icon-key',
      id: "blockchain",
      badge: {
        variant: 'primary',
        text: '*'
      }
    },
    {
      name: 'Tags',
      url: '/admin/tag',
      icon: 'icon-pin',
      id: "admintag",
      badge: {
        variant: 'primary',
        text: '*'
      }
    },
    {
      name: 'Organization',
      url: '/admin/org',
      icon: 'icon-globe',
      id: "adminorg",
      badge: {
        variant: 'primary',
        text: '*'
      }
    }
  ]
}
