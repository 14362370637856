<template>
    <div>
        <label>Default Value</label>
        <div class="float-right buttons">
            <b-button variant="light" size="sm" :class="{ active: mode === 'basic'}" @click="mode = 'basic'"><i class="fas fa-i-cursor"/></b-button>
            <b-button variant="light" size="sm" :class="{ active: mode === 'js'}" @click="mode = 'js'"><i class="fab fa-js-square"/></b-button>
        </div>
        <div v-if="mode === 'basic'">
            <b-form-input v-model="basicValue"></b-form-input>
        </div>
        <div v-if="mode === 'js'">
            <codemirror
                v-model="jsValue"
                :options="cmOptions"
                class="editor"
                language="javascript"
            />
        </div>
    </div>
</template>

<script>

import { codemirror } from 'vue-codemirror'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/theme/base16-light.css'
import 'codemirror/lib/codemirror.css'
export default {
    props: ['value'],
    components: {
        codemirror,
    },
    data() {
        return {
            mode: 'basic',
            jsValue: '',
            basicValue: '',
            cmOptions: {
              tabSize: 2,
              mode: {
                name: 'javascript',
                json: true
              },
              theme: 'base16-light',
              lineNumbers: true,
              line: true,
              viewportMargin: Infinity,
              autofocus: true,
              autoRefresh: true
            },
        }
    },
    watch: {
        value: {
            handler() {
                if (_.isEqual(this.configValue, this.value)) {
                    return;
                }

                this.mode = 'basic';
                this.basicValue = '';
                this.jsValue    = '';

                if (!this.value) {
                    return;
                }

                if (typeof this.value === 'string') {
                    this.mode = 'basic';
                    this.effectiveValue = this.value;
                } else {
                    this.mode = this.value.mode;
                    this.effectiveValue = this.value.value;
                }
            },
            immediate: true,
        },
        mode() {
            this.emit();
        },
        jsValue() {
            this.emit();
        },
        basicValue() {
            this.emit();
        },
    },
    computed: {
        effectiveValue: {
            get() {
                return this.mode === 'js' ? this.jsValue : this.basicValue;
            },
            set(value) {
                if (this.mode === 'js') {
                    this.jsValue = value;
                } else {
                    this.basicValue = value;
                }
            }
        },
        configValue() {
            return {
                mode: this.mode,
                value: this.effectiveValue,
            };
        }
    },
    methods: {
        emit() {
            this.$emit('input', this.configValue);
        }
    }
}
</script>

<style scoped>
.CodeMirror {
  border: 1px solid #eee;
  height: 100%;
}

.buttons button {
    min-width: 2.2em;
    margin-left: 0.5em;
    margin-bottom: 0.5em;
}

.editor {
    width: 100%;
    height: 10em;
}
</style>
