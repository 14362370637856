import Mustache from 'mustache';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

const globalObject = typeof window === 'undefined'
  ? global
  : window;

export default {
  data() {
    return {
      watchersQueue: [],
      watchers_config: {
        api: {
          execute: null,
          execution: null,
        },
      },
      watching: {},
      echoListeners: [],
    };
  },
  methods: {
    /**
     * Watch data changes
     * @param {object} data
     * @param {array} changes
     */
    watchDataChanges(data, odata, changes) {

      if (this.watchers && this.watchers instanceof Array) {
        this.watchers.forEach(watcher =>  {
          if (changes.indexOf(watcher.watching) !== -1) {
            this.checkWatcher(watcher, data,odata);
          }
        });
      }
    },
    /**
     * Check and trigger a watcher if it matches the condition
     *
     * @param {object} watcher
     * @param {object} data
     */
    checkWatcher(watcher, data,odata) {
      if (!isEqual(this.watching[watcher.watching], get(data, watcher.watching))) {
        this.queueWatcher(watcher, data, odata);
      }

      this.watching[watcher.watching] = JSON.parse(JSON.stringify(get(data, watcher.watching)));
    },
    /**
     * Queue a watcher
     * @param {object} watcher
     * @param {object} data
     */
    queueWatcher(watcher, data, odata) {
      this.watchersQueue.indexOf(watcher) === -1 ? this.watchersQueue.push(watcher) : null;
      this.processWatchersQueue(data, odata);
    },
    /**
     * Process the watchers queue
     * @param {object} data
     */
    processWatchersQueue(data, odata) {
      while (this.watchersQueue.length) {
        const watcher = this.watchersQueue.shift();
        this.callWatcher(watcher, data, odata);
      }
    },
    /**
     * Call a watcher
     *
     * @param {object} watcher
     * @param {object} data
     */
    callWatcher(watcher, data, odata) {
      //if (this.watchers_config.api.execute) {
        const watcherId = watcher.script || watcher.script_id;
        if (!watcherId) {
          globalObject.Solyd.alert2(this.$t('Script not found for the Watcher'), 'warning');
          return;
        }

        let mdata = {};
        mdata = Object.assign(mdata, data, window)
        //console.log("MD: ", mdata);

        const input = Mustache.render(watcher.input_data, data);
        const config = Mustache.render(watcher.script_configuration, data);
        if (watcher.synchronous) {
          // popup lock screen
          if (this.$el.offsetParent) {
            this.$refs.watchersSynchronous.show(watcher.name);
          }
        }

        /*console.log("f95:>>  ",mdata,  watcher, data, {
          watcher: watcher.uid,
          data: input,
          config,
        })*/

        switch (watcher.type) {
          case "script":
            switch (watcher.exec) {
              case "frontend":
                this.$axiosApi
                  .$post('data/getscript?id=' + watcherId, {
                    watcher: watcher.uid,
                    data: input,
                    config: config,
                  })
                  .then((response) => {
                    this.$set(this.transientData, watcher.output_variable, response.output);
                    this.$refs.watchersSynchronous.hide(watcher.name);
                  });
                break;
              case "backend":
                this.$axiosApi
                  .$post('data/execscript?id=' + watcherId, {
                    watcher: watcher.uid,
                    data: input,
                    config: config,
                  })
                  .then((response) => {
                    this.$set(this.transientData, watcher.output_variable, response.output);
                    this.$refs.watchersSynchronous.hide(watcher.name);
                  });
                break;

            }
            break;
          case "API":
            switch (watcher.exec) {
              case "frontend":
                this.$axiosApi
                  .$post('data/getapi?id=' + watcherId, {
                    watcher: watcher.uid,
                    data: input,
                    config: config,
                  })
                  .then((response) => {
                      //Create payload for post request
                      var payload = {app: window.app, data: data};
                      /*
                      //Replace the key-values from mdata for input
                      let jinput = JSON.parse(watcher.input_data);
                      Object.keys(  JSON.parse(watcher.input_data) ).forEach((key, i) => {
                        //console.log("key: ", key, mdata[jinput[key]] )
                        payload[key] = mdata[jinput[key]]
                      });
                      */ //NOT to do for User Privacy - Only send window.app and let the transaction happen thrrough state data
                      console.log(  "f150: PAYLOAD: ", payload," ,Watcher: ", watcher," ,Data: ", data,">",  this.$route.query.inst)
                      //Call the api from local Environment
                      this.$axiosApi
                        .$post(response.data.url, payload)
                        .then((response2) => {
                          this.$set(this.transientData, watcher.output_variable, response2.output);
                          this.$refs.watchersSynchronous.hide(watcher.name);
                        });
                  });
                break;
              case "backend":
                this.$axiosApi
                  .$post('data/execapi?id=' + watcherId, {
                    watcher: watcher.uid,
                    data: input,
                    config: config,
                    app:  this.$route.query.inst,
                    form: data,
                  })
                  .then((response) => {
                    //console.log("f175: ", response.opts)
                    this.$set(this.transientData, watcher.output_variable, response.opts);
                    this.$refs.watchersSynchronous.hide(watcher.name);
                  });
                break;

            }
            break;


        }

        /*
        this.$axiosApi
          .$post('data/execscript?id=' + watcherId, {
            watcher: watcher.uid,
            data: input,
            config: config,
          })
          .then((response) => {
            //this.loading = false
            //this.content = response.opt
            this.$set(this.transientData, watcher.output_variable, response.output);
            this.$refs.watchersSynchronous.hide(watcher.name);
          })
          .catch((error) => {
            this.$refs.watchersSynchronous.error(error);
            this.loading = false
            if (error.response.status === 404) {
              this.content = ''
              this.error = this.$t('Selected script not found')
            }
          })
          */

        /*
        globalObject.window.Solyd.apiClient.post(this.watchers_config.api.execute.replace(/script_id\/script_key/, watcherId), {
          watcher: watcher.uid,
          data: input,
          config,
        });
        */
      //}
    },
    loadWatcherResponse(watcherUid, response) {
      console.log("f102: ",response)
      if (!this.watchers)  {
        return;
      }
      const watcher = this.watchers.find(watcher => watcher.uid === watcherUid);
      new Promise((resolve, exception) => {
        if (response.exception) {
          exception(response.message);
        } else if (watcher) {
          if (response.key) {
            globalObject.window.Solyd.apiClient.get(this.watchers_config.api.execution.replace(/execution_key/, response.key)).then((result) => {
              const response = result.data;
              if (response.exception) {
                exception(response.message);
              } else {
                resolve({response});
              }
            });
          } else {
            resolve({response});
          }
        }
      }).then(({response}) => {
        this.$set(this.transientData, watcher.output_variable, response.output);
        this.$refs.watchersSynchronous.hide(watcher.name);
      }).catch(error => {
        if (watcher.synchronous) {
          if (this.$el.offsetParent) {
            this.$refs.watchersSynchronous.error(error);
          }
        } else {
          globalObject.Solyd.alert(error, 'danger');
        }
      });
    },
    /**
     * Add a Echo listener
     * @param {string} channel
     * @param {string} event
     * @param {function} callback
     */
    addEchoListener(channel, event, callback) {
      this.echoListeners.push({
        channel,
        event,
      });
      globalObject.window.Echo.private(channel).listen(
        event,
        callback,
      );
    },
    /**
     * Clean the registered echo listeners
     */
    cleanEchoListeners() {
      this.echoListeners.forEach(element => {
        globalObject.window.Echo.private(
          element.channel
        ).stopListening(element.event);
      });
    },
  },
  mounted() {
    /*
    //console.log("f165: ", globalObject.window.Solyd, globalObject.window.Solyd.user)
    if (globalObject.window.Solyd && globalObject.window.Solyd.user) {
      const channel = `Solyd.Models.User.${globalObject.window.Solyd.user.id}`;
      const event = 'Solyd\\Notifications\\ScriptResponseNotification';
      globalObject.window.Echo.private(channel).notification(
        (data) => {
          if (data.type === event) {
            this.loadWatcherResponse(data.watcher, data.response);
          }
        },
      );
    }
    this.processWatchersQueue = debounce(this.processWatchersQueue, 1000);
    */
  },
  destroyed() {
    this.cleanEchoListeners();
  },
};
