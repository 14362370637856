import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'
import __ from '~/plugins/translator.js'
import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')

// import axios from 'axios'

// axios.defaults.baseURL = 'https://bpm4.local.solyd.com/api/1.0/'
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
// axios.defaults.timeout = 5000
// Import any of available themes

Vue.use(VueTour)
Vue.use(VueToast, {
  position: 'top-right',
  duration: 1000
})

window.user = {
  cyberium:{address:null},
  name:null,
  phone:null,
  email:null,
  wallet:[],
  timezone :Intl.DateTimeFormat().resolvedOptions().timeZone

}
window.Solyd= {
  nodeTypes: [],
  navbar: {
    alerts: []
  },
  EventBus: new Vue(),
  // apiClient: axios,
  alert2(msg, variant, showValue = 60, stayNextScreen = false) {
    console.log(msg)
    if (showValue === 0) {
      showValue = true
    }

    window.Solyd.navbar.alerts.push({
      alertText: msg,
      alertShow: showValue,
      alertVariant: String(variant),
      stayNextScreen
    })
    window.Solyd.EventBus.$emit('alert', window.Solyd.navbar.alerts)
  },
  confirmModal(title, message, variant, callback) {
    if (window.confirm(`${title}: ${message}`)) {
      callback();
    }
  },
  alert(message, variant) {
    variant;
    message;
  },
  toast(msg, type) {
    if (type) {
      Vue.$toast[type](msg)
    } else {
      Vue.$toast.default(msg)
    }
  }
}
