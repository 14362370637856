export default function({ app, $auth, $axios, redirect }, inject) {
  // Create a custom axios instance
  const FALLBACK_INTERVAL = 30*24*60*60* 0.75

  let refreshInterval = FALLBACK_INTERVAL;
  let refreshCallFlag = false;

  let axiosApi = $axios.create({
    // baseURL: 'https://app.fastbuilder.ai',
    timeout: 500000,
    headers: {
      Authorization: $auth.getToken('local') || $auth.getToken('otp'),
      common: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    }
  });

  $auth.onRedirect((to, from) => {
    console.log("onRedirect", to, from, app?.router);
    const switchAccount = app?.router?.history?.pending?.query?.switchaccount || "";
    // debugger
    if(from === '/login' && (switchAccount && switchAccount == "true")){
      return '/login'
    }
  })

  async function refreshTokenF($auth, $axios, refreshToken) {
    try {


        //$auth.refreshTokens();

        const response = await $axios.get(process.env.baseUrl+'/public/refresh')
        let token = 'Bearer ' + response.data.token
        console.log(refreshToken);
        console.log(token);
        var strategy = $auth.getToken('local')?'local': ($auth.getToken('otp')?'otp':'pass');
        $auth.setToken(strategy, token)


        $axios.setToken(token)
        $axios.setHeader(
          "Authorization",
          token
        )

        //return decodeToken.call(this, token).exp
    } catch (error) {
        //$auth.logout()
        console.log(">>", error)
        throw new Error('Error while refreshing token')
    }
}


  axiosApi.setBaseURL(process.env.baseUrl)
  // axiosApi.setBaseURL('http://localhost:2017/')

  axiosApi.onRequest((config) => {
    var token = $auth.getToken('local') || $auth.getToken('otp') || $auth.getToken('pass') ;

    //console.log('Making request to ', config.url ,(config.headers.Authorization), $auth.getToken('local'))
    if(config.headers.Authorization != token && token != null ){
      config.headers.common['Authorization']=token
      config.headers.Authorization  = token;
      //window.location.reload();
      /*axiosApi = $axios.create({
        // baseURL: 'https://app.fastbuilder.ai',
        timeout: 500000,
        headers: {
          Authorization: $auth.getToken('local'),
          common: {
            'X-Requested-With': 'XMLHttpRequest'
          }
        }
      });*/
    }
    /*if(!refreshCallFlag){
      console.log(">>>Refresh Flag:")
      refreshCallFlag = true;
      setInterval(async function () {
          token = $auth.getToken('local') || $auth.getToken('otp');
          //await refreshTokenF($auth, $axios, token)
      }, refreshInterval*1000)

    }*/
  });

  axiosApi.onError(async (error) => {
    var token = $auth.getToken('local') || $auth.getToken('otp') ||  $auth.getToken('pass') ;
    const code = parseInt(error.response && error.response.status)
    if (code === 400) {
      redirect('/400')
    }
    if (code === 401) {
      //alert(code)
      try {
          $auth.logout()
          //await refreshTokenF($auth,$axios,token);
        } catch (e) {
          console.log("S69: ", e)
          //$auth.logOut();
        }

      console.log("S28:", location.href )
      //this.$emit("input", !this.value);
      //TODO Pop-up Login/Signup
      //window.location.replace('/login')
    }
    console.log("Es: ",error,"code:", code)

  })

  const refresh = () => {
    var token = $auth.getToken('local') || $auth.getToken('otp') || $auth.getToken('pass') ;
    axiosApi = $axios.create({
      // baseURL: 'https://app.fastbuilder.ai',
      timeout: 500000,
      headers: {
        Authorization: token,
        common: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      }
    });
  }

  // Inject to context as $axiosApi
  inject('axiosApi', axiosApi)


}
