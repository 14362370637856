<template>
  <div>
    <div :class="{ 'has-error': error }">
      <form-checkbox
        :label="$t('Display Next Assigned Task to Task Assignee')"
        :checked="allowInterstitialGetter"
        @change="allowInterstitialSetter"
      >
      </form-checkbox>
      <small v-if="error" class="text-danger">{{ error }}</small>
      <small v-if="helper" class="form-text text-muted">{{ $t(helper) }}</small>
    </div>
    <screen-select
      v-if="allowInterstitialGetter"
      v-model="screen"
      :label="$t('Screen Interstitial')"
      :placeholder="$t('Default Screen')"
      :helper="$t('What Screen Should Be Used For Rendering This Interstitial')"
      :params="parameters"
    />
  </div>
</template>

<script>
import ScreenSelect from './ScreenSelect'
import highlightedNode from '~/mixins/highlightedNode'

export default {
  components: { ScreenSelect },
  mixins: [highlightedNode],
  props: ['value', 'label', 'helper'],
  data() {
    return {
      screen: null,
      loading: false,
      error: '',
      parameters: {
        type: 'DISPLAY'
      }
    }
  },
  computed: {
    /**
     * Get the value of the edited property
     */
    allowInterstitialGetter() {
      const value = _.get(this.node, 'allowInterstitial')

      this.screen = _.get(this.node, 'interstitialScreenRef')

      return value
    },

    node() {
      return this.highlightedNodeDefinition
    }
  },
  watch: {
    screen: {
      handler(value) {
        this.$set(this.node, 'interstitialScreenRef', value)
      }
    }
  },
  methods: {
    /**
     * Update allowInterstitial property
     */
    allowInterstitialSetter(value) {
      this.$set(this.node, 'allowInterstitial', value)
    }
  }
}
</script>
