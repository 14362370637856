export const state = () => ({
    user: {}
})

export const mutations = {
    add(state, data) {
        state.user = data;
    },
    remove(state, todo) {
        state.list = state.list.filter(item => item.id !== todo.id)
    },
    toggle(state, todo) {
        todo.done = !todo.done
    },
    appId(state, data) {
        state.appId = data
    }
}