<template>
  <crown-button
    v-if="!invalidSequenceFlowSources.includes(node.type)"
    id="sequence-flow-button"
    v-b-tooltip.hover.viewport.d50="{ customClass: 'no-pointer-events' }"
    :title="$t('Sequence Flow')"
    aria-label="Create a sequence flow"
    :src="sequenceFlow"
    role="menuitem"
    @click="addSequence"
  />
</template>
<script>
import sequenceFlow from '~/assets/connect-elements.svg'
import CrownButton from '~/components/crown/crownButtons/crownButton'
import Node from '~/components/nodes/node'

export default {
  components: { CrownButton },
  props: ['node', 'sequenceFlowConfig', 'moddle'],
  data() {
    return {
      sequenceFlow,
      invalidSequenceFlowSources: [
        'solyd-modeler-sequence-flow',
        'solyd-modeler-message-flow',
        'solyd-modeler-end-event',
        'solyd-modeler-error-end-event',
        'solyd-modeler-message-end-event',
        'solyd-modeler-lane',
        'solyd-modeler-text-annotation',
        'solyd-modeler-pool',
        'solyd-modeler-association'
      ]
    }
  },
  created() {
    this.$t = this.$t.bind(this)
  },
  methods: {
    addSequence(cellView, evt, x, y) {
      this.$emit('toggle-crown-state', false)
      const sequenceLink = this.sequenceFlowConfig.definition(
        this.moddle,
        this.$t
      )
      sequenceLink.set('sourceRef', this.node.definition)
      sequenceLink.set('targetRef', { x, y })

      if (
        sequenceLink.sourceRef.$type === 'bpmn:ExclusiveGateway' ||
        sequenceLink.sourceRef.$type === 'bpmn:InclusiveGateway'
      ) {
        sequenceLink.conditionExpression = this.moddle.create(
          'bpmn:FormalExpression',
          {
            body: ''
          }
        )
      }

      this.$emit(
        'add-node',
        new Node(
          'solyd-modeler-sequence-flow',
          sequenceLink,
          this.sequenceFlowConfig.diagram(this.moddle)
        )
      )
    }
  }
}
</script>
