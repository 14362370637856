import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import endEventConfig from '../endEvent'
import component from './mailEndEvent.vue'
import omit from 'lodash/omit'
import CatchEventMailSelect from '../intermediateMailCatchEndEvent/CatchEventMailSelect'

export const id = 'solyd-modeler-mail-end-event'

export default merge(cloneDeep(endEventConfig), {
  id,
  component,
  control: false,
  label: 'Mail End Event',
  definition(moddle, $t) {
    return moddle.create('bpmn:EndEvent', {
      name: $t('Mail End Event'),
      eventDefinitions: [moddle.create('bpmn:MailEventDefinition')]
    })
  },
  allowOutgoingFlow() {
    return false
  },
  inspectorData(node) {
    return Object.entries(node.definition).reduce((data, [key, value]) => {
      if (key === 'eventDefinitions') {
        data.MailRef = value[0].get('mailRef')
      } else {
        data[key] = value
      }
      return data
    }, {})
  },
  inspectorHandler(value, node, setNodeProp, moddle) {
    for (const key in omit(value, ['$type', 'MailRef'])) {

      if (node.definition[key] === value[key]) {
        continue
      }

      setNodeProp(node, key, value[key])
    }

    if (
      node.definition.eventDefinitions[0].get('MailRef') !== value.mailRef
    ) {
      //console.log("in47: ", value.mailRef)
      setNodeProp(node, 'eventDefinitions', [
        moddle.create('bpmn:MailEventDefinition', {
          mailRef: value.mailRef
        })
      ])
    }
  },
  inspectorConfig: [
    {
      items: [
        {
          items: [
            {},
            {
              component: CatchEventMailSelect,
              config: {
                label: 'Mailer App  ',
                name: 'mailRef',
                helper:
                  'Select Mail to transmit',
                  helper2: 'Select transmit mailer'
              }
            }
          ]
        }
      ]
    }
  ]
})
