var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card-footer',{staticClass:"p-0 border-0"},[_c('div',{staticClass:"statusbar d-flex align-items-center justify-content-end pr-3 border-top"},[_c('div',[_c('div',{staticClass:"status-bar-container d-flex align-items-center justify-content-end"},[(_vm.autoValidate)?[(_vm.numberOfProblemsToDisplay === 0)?_c('button',{staticClass:"btn btn-light",attrs:{"type":"button","disabled":true}},[_vm._v("\n            "+_vm._s(_vm.$t('BPMN Valid'))+"\n            "),_c('span',{staticClass:"badge badge-success badge-pill"},[_c('font-awesome-icon',{attrs:{"icon":_vm.faCheck}})],1)]):_c('button',{staticClass:"btn btn-light",attrs:{"type":"button","data-test":"validation-list-toggle"},on:{"click":function($event){_vm.shouldDisplayProblemsPanel = !_vm.shouldDisplayProblemsPanel}}},[_vm._v("\n            "+_vm._s(_vm.$t('BPMN Issues'))+"\n            "),_c('span',{staticClass:"badge badge-primary badge-pill"},[_vm._v("\n              "+_vm._s(_vm.numberOfProblemsToDisplay)+"\n            ")]),_vm._v(" "),_c('font-awesome-icon',{staticClass:"ml-3",attrs:{"icon":_vm.chevronIcon}})],1)]:_vm._e(),_vm._v(" "),_c('span',{staticClass:"divider"}),_vm._v(" "),_c('b-form-checkbox',{staticClass:"h-100 d-flex align-items-center",attrs:{"data-test":"validation-toggle","switch":""},model:{value:(_vm.autoValidate),callback:function ($$v) {_vm.autoValidate=$$v},expression:"autoValidate"}},[_vm._v("\n          "+_vm._s(_vm.$t('Auto validate'))+"\n        ")]),_vm._v(" "),_c('transition',{attrs:{"name":"slide"}},[(_vm.isProblemsPanelDisplayed)?_c('div',{staticClass:"validation-container position-absolute text-left"},[_c('dl',{staticClass:"validation-container__list align-items-baseline",attrs:{"data-test":"validation-list"}},[_vm._l((_vm.errorList),function(error){return [_c('dt',{key:error.errorId,staticClass:"text-capitalize"},[_c('font-awesome-icon',{staticClass:"status-bar-container__status-icon ml-1 mr-1 mt-1",style:({
                      color: _vm.isErrorCategory(error)
                        ? _vm.errorColor
                        : _vm.warningColor
                    }),attrs:{"icon":_vm.isErrorCategory(error)
                        ? _vm.faTimesCircle
                        : _vm.faExclamationTriangle}}),_vm._v("\n                  "+_vm._s(error.errorKey)+"\n                ")],1),_vm._v(" "),_c('dd',{key:`${error.errorId}_dd`},[_c('p',{staticClass:"pl-4 mb-0 font-italic"},[_vm._v(_vm._s(error.message)+".")]),_vm._v(" "),(error.id)?_c('p',{staticClass:"pl-4 mb-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('Node ID'))+":")]),_vm._v("\n                    "+_vm._s(error.id)+"\n                  ")]):_vm._e()])]}),_vm._v(" "),_vm._l((_vm.warnings),function(warning,index){return [_c('dt',{key:warning.title + index,staticClass:"text-capitalize"},[_c('font-awesome-icon',{staticClass:"status-bar-container__status-icon ml-1 mr-1 mt-1",style:({ color: _vm.warningColor }),attrs:{"icon":_vm.faExclamationTriangle}}),_vm._v("\n                  "+_vm._s(warning.title)+"\n                ")],1),_vm._v(" "),_c('dd',{key:warning.title + index + '__dd',staticClass:"font-italic pl-4"},[_vm._v("\n                  "+_vm._s(warning.text)+"\n                ")])]})],2)]):_vm._e()])],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }