<template>
  <b-modal
    ref="modal"
    size="lg"
    id="custom-css"
    :title="$t('Custom CSS ')"
    @ok="save"
    @cancel="close"
    @hide="hide"
    cancel-variant="btn btn-outline-secondary"
    ok-variant="btn btn-secondary ml-2"
    no-close-on-backdrop
    :ok-title="$t('Save')"
    :cancel-title="$t('Cancel')"
    header-close-content="&times;"
  >
    <p>
      {{
        $t(
          "You can set CSS Selector names in the inspector. Use them here with [selector='my-selector']"
        )
      }}
    </p>
    <div class="editor">
      <codemirror :options="cmOptions" v-model="innerValue" />
    </div>

    <b-alert :show="cssErrors != ''" variant="danger">
      <pre>{{ cssErrors }}</pre>
    </b-alert>
    <div slot="modal-cancel">{{ $t('Cancel') }}</div>
    <div slot="modal-ok">{{ $t('Save') }}</div>
  </b-modal>
</template>

<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/theme/base16-light.css'
import 'codemirror/lib/codemirror.css'
export default {
  props: ['value', 'cssErrors'],
  components: { codemirror },
  data() {
    return {
      saveValue: '',
      innerValue: '',
      cmOptions: {
        tabSize: 2,
        mode: {
          name: 'javascript',
          json: true
        },
        theme: 'base16-light',
        lineNumbers: true,
        line: true,
        viewportMargin: Infinity,
        autofocus: true,
        autoRefresh: true
      }
    }
  },
  watch: {
    innerValue(value) {
      this.$emit('input', value)
    }
  },
  methods: {
    show() {
      this.innerValue = this.value
      this.saveValue = this.value
      this.$refs.modal.show()
    },
    close() {
      this.$refs.modal.hide()
    },
    hide() {
      this.innerValue = this.saveValue
      this.$emit('input', this.innerValue)
    },
    save() {
      this.saveValue = this.innerValue
      this.$refs.modal.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
.CodeMirror {
  border: 1px solid #eee;
  min-height: 200px;
  height: 100%;
}
.editor {
  width: 100%;
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 5px;
}
</style>
