<script>
import startEvent from '../startEvent/startEvent'
//import signalStartEventIcon from '~/assets/signal-start-event.svg'
import signalStartEventIcon from '~/assets/toolpanel/blockchain.svg'


export default {
  extends: startEvent,
  mounted() {
    this.shape.attr({
      image: {
        'ref-x': 5,
        'ref-y': 3,
        'xlink:href': signalStartEventIcon
      }
    })
  }
}
</script>
