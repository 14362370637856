import bpmnExtension from 'solyd-bpmn-moddle/resources/solyd'
import Vue from 'vue'
import {
  association,
  boundaryErrorEvent,
  boundaryMessageEvent,
  boundarySignalEvent,
  boundaryBlockchainEvent,
  boundaryTimerEvent,
  endEvent,
  errorEndEvent,
  //signalEndEvent,

  mailEndEvent,
  blockchainEndEvent,
  messageEndEvent,
  exclusiveGateway,
  inclusiveGateway,
  parallelGateway,
  sequenceFlow,
  startEvent,
  startTimerEvent,
  //signalStartEvent,
  blockchainStartEvent,
  messageStartEvent,
  subProcess,
  task,
  scriptTask,
  manualTask,
  pool,
  poolLane,
  textAnnotation,
  messageFlow,
  serviceTask,
  callActivity,
  eventBasedGateway,
  intermediateMessageCatchEvent
} from '~/components/nodes'
import SelectUserGroup from '~/components/SelectUserGroup'
import ConfigEditor from '~/components/inspectors/ConfigEditor'
import ExpressionEditor from '~/components/inspectors/ExpressionEditor'
import GroupSelect from '~/components/inspectors/GroupSelect'
import Interstitial from '~/components/inspectors/Interstitial'
import ModelerScreenSelect from '~/components/inspectors/ScreenSelect'
import ScriptSelect from '~/components/inspectors/ScriptSelect'
import StartPermission from '~/components/inspectors/StartPermission'
import TaskAssignment from '~/components/inspectors/TaskAssignment'
import TaskDueIn from '~/components/inspectors/TaskDueIn'
import TaskNotifications from '~/components/inspectors/TaskNotifications'
import UserById from '~/components/inspectors/UserById'
import UserSelect from '~/components/inspectors/UserSelect'

Vue.component('UserSelect', UserSelect)
Vue.component('UserById', UserById)
Vue.component('GroupSelect', GroupSelect)
Vue.component('ModelerScreenSelect', ModelerScreenSelect)
Vue.component('TaskNotifications', TaskNotifications)
Vue.component('ExpressionEditor', ExpressionEditor)
Vue.component('TaskAssignment', TaskAssignment)
Vue.component('TaskDueIn', TaskDueIn)
Vue.component('ConfigEditor', ConfigEditor)
Vue.component('ScriptSelect', ScriptSelect)
Vue.component('StartPermission', StartPermission)
Vue.component('Interstitial', Interstitial)
Vue.component('SelectUserGroup', SelectUserGroup)

const nodeTypes = [
  startEvent,
  endEvent,
  task,
  scriptTask,
  manualTask,
  subProcess,
  callActivity,
  exclusiveGateway,
  inclusiveGateway,
  eventBasedGateway,
  parallelGateway,
  sequenceFlow,
  association,
  pool,
  poolLane,
  messageFlow,
  serviceTask,
  textAnnotation
]


const customEventNodes = [
  [startTimerEvent, 'bpmn:StartEvent', 'bpmn:TimerEventDefinition'],
  //[signalStartEvent, 'bpmn:StartEvent', 'bpmn:SignalEventDefinition'],
  [blockchainStartEvent, 'bpmn:StartEvent', 'bpmn:SignalEventDefinition'],
  [messageStartEvent, 'bpmn:StartEvent', 'bpmn:MessageEventDefinition'],
  [messageEndEvent, 'bpmn:EndEvent', 'bpmn:MessageEventDefinition'],
  [errorEndEvent, 'bpmn:EndEvent', 'bpmn:ErrorEventDefinition'],
  //[signalEndEvent, 'bpmn:EndEvent', 'bpmn:SignalEventDefinition'],
  [mailEndEvent,'bpmn:EndEvent', 'bpmn:MailEventDefinition'], //Bug
  [blockchainEndEvent, 'bpmn:EndEvent', 'bpmn:SignalEventDefinition'], //Bug

  [
    intermediateMessageCatchEvent,
    'bpmn:IntermediateCatchEvent',
    'bpmn:MessageEventDefinition',
    'bpmn:SignalEventDefinition',
    'bpmn:MailEventDefinition'
  ],
  [boundaryTimerEvent, 'bpmn:BoundaryEvent', 'bpmn:TimerEventDefinition'],
  [boundaryErrorEvent, 'bpmn:BoundaryEvent', 'bpmn:ErrorEventDefinition'],
  [boundaryMessageEvent, 'bpmn:BoundaryEvent', 'bpmn:MessageEventDefinition'],
  [boundarySignalEvent, 'bpmn:BoundaryEvent', 'bpmn:SignalEventDefinition'],
  [boundaryBlockchainEvent, 'bpmn:BoundaryEvent', 'bpmn:SignalEventDefinition']
]

const customParserFactory = (
  nodeType,
  primaryIdentifier,
  secondaryIdentifier
) => (definition) => {
  const definitions = definition.get('eventDefinitions')
  const validDefinition =
    definition.$type === primaryIdentifier &&
    definitions &&
    definitions.length &&
    definitions[0].$type === secondaryIdentifier
  if (validDefinition) {
    return nodeType.id
  }
}

window.Solyd.nodeTypes.push(...nodeTypes)

// Set default properties for task
task.definition = function definition(moddle, $t) {
  return moddle.create('bpmn:Task', {
    name: $t('New Task'),
    assignment: 'requester'
  })
}

window.Solyd.EventBus.$on('modeler-init', ({ registerInspectorExtension }) => {
  /* Register extension for start permission */
  registerInspectorExtension(startEvent, {
    component: 'FormAccordion',
    container: true,
    config: {
      initiallyOpen: true,
      label: 'Start-Permissions',
      icon: 'user-shield',
      name: 'inspector-accordion'
    },
    items: [
      {
        component: 'StartPermission',
        config: {
          label: 'Permission To Start',
          helper: 'Select who may start a Request of this Process',
          userHelper: 'Select who may start a Request',
          groupHelper:
            'Select the group from which any user may start a Request',
          name: 'startPermission'
        }
      }
    ]
  })
  /*
  registerInspectorExtension(startEvent, {
    component: 'Interstitial',
    config: {
      label: 'Display Next Assigned Task to Task Assignee',
      helper: 'Directs Task assignee to the next assigned Task',
      name: 'interstitial'
    }
  })
  */

  registerInspectorExtension(startEvent, {
    component: 'ModelerScreenSelect',
    config: {
      label: 'Screen for Input',
      helper: 'Select Screen to display this Start Event',
      name: 'screenRef',
      type: 'FORM'
    }
  })

  /* Register the inspector extensions for tasks */
  registerInspectorExtension(task, {
    component: 'ModelerScreenSelect',
    config: {
      label: 'Screen for Input',
      helper: 'Select Screen to display this Task',
      name: 'screenRef',
      type: 'FORM'
    }
  })
  /*
  registerInspectorExtension(task, {
    component: 'TaskDueIn',
    config: {
      label: 'Due In',
      helper: 'Time when the task will be due',
      name: 'taskDueIn'
    }
  })
  */
  registerInspectorExtension(task, {
    component: 'FormAccordion',
    container: true,
    config: {
      initiallyOpen: false,
      label: 'Assignment-Rules',
      icon: 'users',
      name: 'inspector-accordion'
    },
    items: [
      {
        component: 'TaskAssignment',
        config: {
          label: 'Task Assignment:',
          helper: '',
          name: 'taskAssignment'
        }
      }
    ]
  })
  registerInspectorExtension(task, {
    component: 'FormAccordion',
    container: true,
    config: {
      initiallyOpen: false,
      label: 'Notifications',
      icon: 'bell',
      name: 'inspector-accordion'
    },
    items: [
      {
        component: 'TaskNotifications',
        config: {
          helper: 'Users that should be notified about task events'
        }
      }
    ]
  })

  /*
  registerInspectorExtension(task, {
    component: 'Interstitial',
    config: {
      label: 'Display Next Assigned Task to Task Assignee',
      helper: 'Directs Task assignee to the next assigned Task',
      name: 'interstitial'
    }
  })
  */
  /* Register the inspector extensions for script tasks */
  registerInspectorExtension(scriptTask, {
    component: 'ScriptSelect',
    config: {
      label: 'Script',
      helper: 'Select the Script this element runs',
      name: 'scriptRef'
    }
  })

  registerInspectorExtension(scriptTask, {
    component: 'ConfigEditor',
    config: {
      label: 'Script Configuration',
      helper: 'Enter the JSON to configure the Script',
      name: 'scriptConfiguration',
      property: 'config'
    }
  })
  registerInspectorExtension(endEvent, {
    component: 'ModelerScreenSelect',
    config: {
      label: 'Summary Screen',
      helper:
        'Select Display-type Screen to show the summary of this Request when it completes',
      name: 'screenRef',
      params: { type: 'DISPLAY' }
    }
  })
  registerInspectorExtension(manualTask, {
    component: 'ModelerScreenSelect',
    config: {
      label: 'Screen for Input',
      helper: 'Select Screen to display this Task',
      name: 'screenRef',
      params: { type: 'DISPLAY' }
    }
  })
  /*
  registerInspectorExtension(manualTask, {
    component: 'TaskDueIn',
    config: {
      label: 'Due In',
      helper: 'Enter the hours until this Task is overdue',
      name: 'taskDueIn'
    }
  })
  */
  registerInspectorExtension(manualTask, {
    component: 'FormAccordion',
    container: true,
    config: {
      initiallyOpen: false,
      label: 'Assignment-Rules',
      icon: 'users',
      name: 'inspector-accordion'
    },
    items: [
      {
        component: 'TaskAssignment',
        config: {
          label: 'Task Assignment :',
          helper: '',
          name: 'taskAssignment'
        }
      }
    ]
  })
  registerInspectorExtension(manualTask, {
    component: 'FormAccordion',
    container: true,
    config: {
      initiallyOpen: false,
      label: 'Notifications',
      icon: 'bell',
      name: 'inspector-accordion'
    },
    items: [
      {
        component: 'TaskNotifications',
        config: {
          helper: 'Users that should be notified about task events'
        }
      }
    ]
  })
  registerInspectorExtension(manualTask, {
    component: 'Interstitial',
    config: {
      label: 'Enable Interstitial',
      helper: 'redirected to my next assigned task',
      name: 'interstitial'
    }
  })

  /* Register extension for intermediate message catch event */
  registerInspectorExtension(intermediateMessageCatchEvent, {
    component: 'UserSelect',
    config: {
      label: 'Allowed User',
      helper: 'Select allowed user',
      name: 'allowedUsers'
    }
  })

  registerInspectorExtension(intermediateMessageCatchEvent, {
    component: 'GroupSelect',
    config: {
      label: 'Allowed Group',
      helper: 'Select allowed group',
      name: 'allowedGroups'
    }
  })

  registerInspectorExtension(intermediateMessageCatchEvent, {
    component: 'FormInput',
    config: {
      label: 'Whitelist',
      helper: 'IP/Domain whitelist',
      name: 'whitelist'
    }
  })
})

export default function registerNodes({ registerNode, registerBpmnExtension }) {
  customEventNodes.forEach(
    ([nodeType, primaryIdentifier, secondaryIdentifier]) => {
      registerNode(
        nodeType,
        customParserFactory(nodeType, primaryIdentifier, secondaryIdentifier)
      )
    }
  )

  nodeTypes.forEach((config) => registerNode(config))

  registerBpmnExtension('pm', bpmnExtension)
}
