<template>
  <div class="form-group" :class="{ 'has-error': error }">
    <label>{{ $t(label) }}</label>
    <multiselect
      v-model="content"
      track-by="id"
      label="name"
      :class="{ 'border border-danger': error }"
      :loading="loading"
      :placeholder="placeholder || $t('type here to search')"
      :options="screens"
      :multiple="false"
      :show-labels="false"
      :searchable="true"
      :internal-search="false"
      @open="load"
      @search-change="load"
    >
      <template slot="noResult">
        {{ $t('No elements found. Consider changing the search query.') }}
      </template>
      <template slot="noOptions">
        {{ $t('No Data Available') }}
      </template>
    </multiselect>
    <small v-if="error" class="text-danger">{{ error }}</small>
    <small v-if="helper" class="form-text text-muted">{{ $t(helper) }}</small>
    <a
      v-if="content"
      :href="`/screen/designer/${content.id}`"
      target="_blank"
    >
      {{ $t('Open Screen') }}
      <i class="ml-1 fas fa-external-link-alt" />
    </a>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  props: [
    'value',
    'label',
    'helper',
    'params',
    'requiredMessage',
    'placeholder'
  ],
  data() {
    return {
      content: {},
      loading: false,
      screens: [],
      error: ''
    }
  },
  watch: {
    content: {
      immediate: true,
      handler() {
        if (this.content) {
          console.log(">>", this.content)
          //console.log(">>>", this.content.id)
          this.error = ''
          if(this.content != null){
            this.$emit('input', this.content.id)
          }
        } else if (this.requiredMessage) {
          this.error = this.requiredMessage
        }
      }
    },
    value: {
      immediate: true,
      handler() {
        // Load selected item.
        if (this.value) {
          console.log(">:>", this.value)
          this.loading = true
          this.$axiosApi
            .$get('data/getscr?id=' + this.value)
            .then((response) => {
              console.log(response)
              this.loading = false
              try {
                this.content = response.scr
              } catch (e) {
                console.log("BAD Screen: ", response.scr)
                this.content=""
              } finally {

              }

            })
            .catch((error) => {
              this.loading = false
              if (error.response.status === 404) {
                this.content = ''
                this.error = this.$t('Selected screen not found')
              }
            })
        } else {
          this.content = ''
          if (this.requiredMessage) {
            this.error = this.requiredMessage
          } else {
            this.error = ''
          }
        }
      }
    }
  },
  methods: {
    type() {
      if (this.params && this.params.type) {
        return this.params.type
      }
      return 'FORM'
    },
    load(filter) {
      const params = Object.assign(
        {
          type: this.type(),
          order_direction: 'asc',
          status: 'active',
          filter: typeof filter === 'string' ? filter : ''
        },
        this.params
      )
      this.loading = true
      this.$axiosApi
        .$get('data/getscrs', {
          params
        })
        .then((response) => {
          //console.log(response)
          this.loading = false
          this.screens = response.scrs.rows
        })
        .catch((err) => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'vue-multiselect/dist/vue-multiselect.min.css';
</style>
