<template>
  <div>
    <label>{{ $t(label) }}</label>
    <multiselect
      v-model="content"
      track-by="id"
      label="name"
      v-bind="$attrs"
      :value="blockchainId"
      :class="{ 'border border-danger': error }"
      :loading="loading"
      :placeholder="$t('type here to search')"
      :options="options"
      :multiple="false"
      :show-labels="false"
      :searchable="true"
      :internal-search="false"
      @open="load"
      @search-change="load"
      @input="emitBlockchain"
    >
      <template slot="noResult">
        {{ $t('No elements found. Consider changing the search query.') }}
      </template>
      <template slot="noOptions">
        {{ $t('No Data Available') }}
      </template>
    </multiselect>
    <small v-if="error" class="text-danger">{{ error }}</small>
    <small v-if="helper" class="form-text text-muted">{{ $t(helper) }}</small>
    <a
      v-if="content"
      :href="`/admin/blockchain?id=${content.id}`"
      target="_blank"
    >
      {{ $t('Open Blockchain') }}
      <i class="ml-1 fas fa-external-link-alt" />
    </a>
    <multiselect
      v-model="method"
      track-by="id"
      label="name"
      v-bind="$attrs"
      :loading="loading_methods"
      :placeholder="$t('type here to search methods')"
      :multiple="false"
      :options="methods"
      :show-labels="false"
      :searchable="true"
      :internal-search="false"
      @input="emitMethod"
    >
      <template slot="noResult">
        {{ $t('No methods found. Consider changing the smart contract.') }}
      </template>
      <template slot="noOptions">
        {{ $t('No methods available') }}
      </template>
    </multiselect>
    <small v-if="helper2" class="form-text text-muted">{{ $t(helper2) }}</small>
  </div>
</template>


<script>
import Multiselect from 'vue-multiselect'
import {
  getBlockchainsList,
  getBlockchain
} from './intermediateBlockchainCatchEventUtils'

export default {
  name:"CatchEvent",
  components: {
    Multiselect
  },
  inheritAttrs: false,
  props: ['value','bkey', 'label', 'helper', 'helper2', 'requiredMessage'],
  data() {
    return {
      content: false,
      loading: false,
      options: [],
      error: '',
      name: '',
      id: '',
      bc: '',
      method:false,
      methods:[],
      loading_methods: false,
    }
  },
  watch: {
    method: {
      immediate: true,
      handler() {
        if (this.method) {
          console.log("MW>>>",this.method, this.method.name, this.value)
          let idvn = this.value.split(":");
          //if (idvn.length == 1){
          idvn[1] = this.method.name;
          idvn[2] = this.method.sign;
          idvn[3] = this.method.address;
          //}
          let cont2 = this.content;
          cont2.id = idvn[0]+":"+idvn[1]+":"+idvn[2]+":"+idvn[3];
          //console.log("MW2>>>", this.content)
          this.content = cont2;
          //this.$emit('input', this.content.id)
        }
      }
    },
    content: {
      immediate: true,
      handler() {
        if (this.content) {
          //console.log(">>>", this.content.id)
          this.error = ''
          this.$emit('input', this.content.id)
          console.log('>>', this.content.id, '>', this.value, '<')
        } else if (this.requiredMessage) {
          this.error = this.requiredMessage
        } else {
          console.log('>M>', this.content, this.value, this.$attrs)
        }
      }
    },
    value: {
      immediate: true,
      handler() {
        // Load selected item.
        if (this.value) {
          let idvn = this.value.split(":");
          if (idvn.length == 1){
            if(this.method == ''){
              idvn[1] = 'io';
              this.method = {name:'io',sign:'0x0'}
            }
            else{
              console.log("133: ", this.method.sign)
              idvn[1] = this.method.name;
              idvn[2] = this.method.sign;
              idvn[3] = this.method.address;
            }
          }
          //this.method = idvn[1]
          console.log(">:>", this.value)

            this.loading = true
            this.$axiosApi
              .$get('data/getbc?type=events&id=' + idvn[0])
              .then((response) => {
                //console.log(response)
                this.loading = false
                response.opt.id = response.opt.id+":"+idvn[1]+":"+idvn[2]+":"+response.opt.address
                this.method = {name:idvn[1],sign:idvn[2],address:response.opt.address}
                this.content = response.opt
                this.methods = response.ext;
              })
              .catch((error) => {
                this.loading = false
                if (error.response.status === 404) {
                  this.content = ''
                  this.error = this.$t('Selected blockchain not found')
                }
              });


        } else {
          this.content = ''
          if (this.requiredMessage) {
            this.error = this.requiredMessage
          } else {
            this.error = ''
          }
        }
      }
    }
  },
  computed: {
    async blockchainsList() {
      let bcs = await getBlockchainsList(this.$axiosApi)
      //let bcs = await this(this.$axiosApi)
      console.log('C23, ', bcs, this.value)
      this.options = bcs
      return bcs
    },
    blockchainId() {
      console.log('c121: ', this.value, this.$attrs, this.$props, this.$attrs.name)
      return this.value ? this.value.id : ''
    }
  },
  methods: {
    load(filter) {
      this.$axiosApi
        .$get('/data/getbcs')
        .then((response) => {
          if (response.opts) {
            this.options = response.opts
          }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    async emitBlockchain(Id) {
      //Id = this.options[0]
      if(Id == null)
        return
      const blockchain = await getBlockchain(this.$axiosApi, Id.id)
      console.log('C34, ', blockchain, Id, this.value)

      this.$emit('input', blockchain.id)
    },
    loadmethod(filter){
      //console.log(">>182>>:  ", this.content, this.value)
      if(this.content != null){

      }

    },
    async emitMethod(method){
      //console.log("EM>>>>>> ",method)
      if(method)
        this.$emit('select', method.name)
    }
  }
}
</script>
