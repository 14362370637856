<template>
  <div>
    <div class="form-group">
      <label>{{ $t('Type') }}</label>
      <select
        class="form-control"
        :value="assignmentGetter"
        @input="assignmentSetter"
      >
        <option value="">{{ $t('Select...') }}</option>
        <option value="user">{{ $t('User') }}</option>
        <option value="group">{{ $t('Group') }}</option>
      </select>
      <small v-if="helper" class="form-text text-muted">{{ $t(helper) }}</small>
    </div>

    <div v-if="assignmentGetter" class="form-group">
      <label class="text-capitalize">{{ $t(assignmentGetter) }}</label>
      <multiselect
        v-model="content"
        track-by="id"
        label="name"
        :class="{ 'border border-danger': error }"
        :loading="loading"
        :placeholder="$t('type here to search team/group')"
        :options="options"
        :multiple="false"
        :show-labels="false"
        :searchable="true"
        :internal-search="false"
        @open="load"
        @search-change="load"
      >
        <template slot="noResult">
          {{ $t('No elements found. Consider changing the search query.') }}
        </template>
        <template slot="noOptions">
          {{ $t('No Data Available') }}
        </template>
      </multiselect>
      <small v-if="error" class="text-danger">{{ error }}</small>
      <small v-if="helperText" class="form-text text-muted">{{
        $t(helperText)
      }}</small>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import highlightedNode from '~/mixins/highlightedNode'

export default {
  mixins: [highlightedNode],
  props: ['value', 'label', 'helper', 'userHelper', 'groupHelper', 'property'],
  components: {
    Multiselect
  },
  data() {
    return {
      content: null,
      options: [],
      loading: false,
      error: '',
      type: '',
      labelGroups: this.$t('Groups'),
      labelAssets: this.$t('Assets'),
    }
  },
  computed: {
    helperText() {
      return this.type === 'user' ? this.userHelper : this.groupHelper
    },
    assignmentGetter() {
      const value = _.get(this.highlightedNodeDefinition, 'assignment')
      this.type = value
      return value
    }
  },
  watch: {
    content: {
      handler() {
        if (this.type === 'user' && this.content) {
          this.assignedUserSetter(this.content.id)
        } else if (this.type === 'group' && this.content) {
          this.assignedGroupSetter(this.content.id)
        }
      }
    },
    value: {
      immediate: true,
      handler() {
        this.loadAssigned()
      }
    }
  },
  mounted() {
    this.loadAssigned()
  },
  methods: {
    load(filter) {
      this.options = []
      if (this.type === 'user') {
        this.loadUsers(filter)
      } else if (this.type === 'group') {
        this.loadGroups(filter)
      }
    },
    loadUsers(filter) {
      this.loading = true
      this.$axiosApi
        .$get(
          'data/getmembers?order_direction=asc&status=active' +
            (typeof filter === 'string' ? '&filter=' + filter : '')
        )
        .then((response) => {
          this.loading = false
          this.options = response.opts.map((item) => {
            return {
              id: item.uid,
              name: item.name
            }
          })
        })
        .catch((err) => {
          this.loading = false
        })
    },
    loadGroups(filter) {
      this.loading = true
      this.$axiosApi
        .$get(
          'data/getteams?order_direction=asc&status=active' +
            (typeof filter === 'string' ? '&filter=' + filter : '')
        )
        .then((response) => {
          this.loading = false
          const groups = response.teams.map((item) => {
            return {
              id: "group-"+item.id,
              name: "Group/"+item.name,
              type:"group"
            }
          })

          this.options = ( groups)

          const assets = response.assets.map((item) => {

            return {
              id: "asset-"+item.id,
              name: "Asset/"+item.name,
              type:"asset"
            }
          })

          this.options = [].concat( groups,assets)

          /* const groups = response.teams.map((item) => {

            return this.formatGroup(item)
          })

          const assets = response.assets.map((item) => {

            return this.formatAsset(item)
          })

          if (groups) {
            this.options.push({
              type: this.labelGroups,
              items: groups
            })
          }

          if (assets) {
            this.options.push({
              type: this.labelAssets,
              items: assets
            })
          } */

          /*
          this.options = response.teams.map((item) => {
            return {
              id: item.id,
              name: item.name
            }
          })*/

        })
        .catch((err) => {
          this.loading = false
        })
    },
    loadAssigned() {
      let value = _.get(this.highlightedNodeDefinition, 'assignment')
      this.type = value
      this.content = null
      if (this.type === 'user') {
        value = _.get(this.highlightedNodeDefinition, 'assignedUsers')
        if (!value) {
          return
        }
        this.loading = true
        this.$axiosApi
          .$get('data/getmember?id=' + value)
          .then((response) => {
            this.loading = false
            this.content = {
              id: response.opt.id,
              name: response.opt.name
            }
          })
          .catch((error) => {
            this.loading = false
            if (error.response.status === 404) {
              this.content = ''
              this.error = this.$t('Selected user not found')
            }
          })
      } else if (this.type === 'group') {
        value = _.get(this.highlightedNodeDefinition, 'assignedGroups')
        if (!value) {
          return
        }
        this.loading = true
        if(value.includes('group-')){
          this.$axiosApi
          .$get('data/getteam?id=' +  value.replace("group-",""))
          .then((response) => {
            this.loading = false
            this.content = {
              id: "group-"+response.opt.id,
              name: "Group/"+response.opt.name
            }
          })
          .catch((error) => {
            this.loading = false
            if (error.response.status === 404) {
              this.content = ''
              this.error = this.$t('Selected group not found')
            }
          })
        }else{
          this.$axiosApi
          .$get('data/getassetbyid?id=' +  value.replace("asset-",""))
          .then((response) => {
            this.loading = false
            this.content = {
              id: "asset-"+response.opt.id,
              name: "Asset/"+response.opt.name
            }
          })
          .catch((error) => {
            this.loading = false
            if (error.response.status === 404) {
              this.content = ''
              this.error = this.$t('Selected asset not found')
            }
          })
        }

      }
    },

    assignedUserSetter(id) {
      // let value = _.get(node, 'assignedUsers')
      this.$set(this.highlightedNodeDefinition, 'assignedUsers', id)
      // value = _.get(node, 'assignedGroups')
      this.$set(this.highlightedNodeDefinition, 'assignedGroups', '')
    },
    assignedGroupSetter(id) {
      // let value = _.get(node, 'assignedUsers')
      this.$set(this.highlightedNodeDefinition, 'assignedUsers', '')
      // value = _.get(node, 'assignedGroups')
      this.$set(this.highlightedNodeDefinition, 'assignedGroups', id)
    },
    assignmentSetter(event) {
      this.type = event.target.value
      this.content = null
      this.$set(this.highlightedNodeDefinition, 'assignment', this.type)
      this.load()
    },
    formatGroup(item) {
      console.log(">4>", item)
      item.id = 'group-' + item.id
      item.name = "Group/"+item.name
      return item
    },
    formatAsset(item) {
      console.log(">5>", item)
      item.id = 'asset-' + item.id
      item.name =  "Asset/"+item.name
      return item
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'vue-multiselect/dist/vue-multiselect.min.css';

.form-group {
  padding: 0px;
}
</style>
