<script>
import EndEvent from '~/components/nodes/endEvent/endEvent'
import EventIcon from '~/assets/toolpanel/blockchain.svg'

export default {
  extends: EndEvent,
  mounted() {
    this.shape.attr({
      image: {
        'ref-y': 3,
        'xlink:href': EventIcon
      }
    })
  }
}
</script>
