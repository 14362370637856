import isValidBoundaryEventTarget from '~/components/nodes/boundaryEvent/validBoundaryEventTargets'
import { getEmptyBoundaryEventPositionsForShape } from '~/utils/portsUtils'

export function canAddBoundaryEventToTarget(boundaryEventType, dropTarget) {
  if (!dropTarget || !isValidBoundaryEventTarget(dropTarget.component)) {
    return false
  }

  if (boundaryEventType === 'solyd-modeler-boundary-message-event') {
    return (
      dropTarget.component.node.isBpmnType('bpmn:CallActivity') &&
      spaceAvailable(dropTarget)
    )
  }

  return spaceAvailable(dropTarget)
}

export function isValidBoundaryEscalationEvent(component) {
  return component && component.node.isBpmnType('bpmn:CallActivity')
}

function spaceAvailable(dropTarget) {
  return getEmptyBoundaryEventPositionsForShape(dropTarget).length !== 0
}
