<template>
  <div class="app">
    <b-modal v-model="loginmodal" title="Login to proceed" @hide="hide">
      <div slot="modal-title">
        <h5 class="modal-title">Login to proceed</h5>
      </div>
      <div class="modal-content">
        <div class="modal-body">
          <b-button @click="$router.go()">
            <i class="fas fa-redo"></i> Refresh here after login</b-button
          >
          <br />
          <a target="_blank" @click="login" class="success">
            Go to Login page <i class="fas fa-arrow-circle-right"></i>
          </a>
        </div>
      </div>
      <div slot="modal-footer">No guest record found.</div>
    </b-modal>
    <AppHeader fixed />
    <div class="app-body">
      <main class="">
        <div class="container-fluid">
          <nuxt />
        </div>
      </main>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import { Header as AppHeader, Footer as AppFooter } from "~/components/coreui/"
import LogModal from "~/components/common/logup"
export default {
  name: "Full",
  components: {
    AppHeader,
    AppFooter,
    LogModal
  },
  async mounted() {
    //this.$store.commit('user/add', this.$auth.user)
    // .then(function(data){
    //   console.log("Fetched",data)
    // });
    this.user = await this.$store.state.auth.user
    //console.log("Def: ", this.user, this.$store.state.user.appId)
    if (this.user) {
      this.loginmodal = false
    } else {
      console.log("D58:   ", this.user, this.$auth)
      //alert("22")
      this.loginmodal = true
    }
  },
  data() {
    return {
      user: {},
      loginmodal: false
    }
  },
  methods: {
    login() {
      let url = this.$store.state.user.appId
        ? `/appregister?id=${this.$store.state.user.appId}`
        : `/login`
      this.$router.push(url)
    },
    hide(e) {
      e.preventDefault()
    }
  },
  computed: {
    name() {
      console.log("D: ", this.$route.name)
      return this.$route.name
    },
    list() {
      return this.$route.matched
    }
  }
}
</script>
