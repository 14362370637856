<template>
  <div>
    <label>{{ $t(label) }}</label>
    <multiselect
      v-model="content"
      track-by="id"
      label="name"
      v-bind="$attrs"
      :class="{ 'border border-danger': error }"
      :loading="loading"
      :placeholder="$t('type here to search')"
      :options="options"
      :multiple="false"
      :show-labels="false"
      :searchable="true"
      :internal-search="false"
      @open="load"
      @search-change="load"
      @input="emitMail"
    >
      <template slot="noResult">
        {{ $t('No elements found. Consider changing the search query.') }}
      </template>
      <template slot="noOptions">
        {{ $t('No Data Available') }}
      </template>
    </multiselect>
    <small v-if="error" class="text-danger">{{ error }}</small>
    <small v-if="helper" class="form-text text-muted">{{ $t(helper) }}</small>
    <a
      v-if="content"
      :href="`/admin/mail?id=${content.id}`"
      target="_blank"
    >
      {{ $t('Open Mail') }}
      <i class="ml-1 fas fa-external-link-alt" />
    </a>
    <!--input id="method" v-model="method" class="form-control" /-->
    <multiselect
      v-model="method"
      track-by="id"
      label="type"
      v-bind="$attrs"
      :value="mailTemplate"
      :loading="loading_methods"
      :placeholder="$t('type to search methods')"
      :multiple="false"
      :options="methods"
      :show-labels="false"
      :searchable="true"
      :internal-search="false"
      @input="emitMethod"
    >
      <template slot="noResult">
        {{ $t('No methods found. Consider changing the smart contract.') }}
      </template>
      <template slot="noOptions">
        {{ $t('No methods available') }}
      </template>
    </multiselect>

    <small v-if="helper2" class="form-text text-muted">{{ $t(helper2) }}</small>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
/*
import {
  getMailsList,
  getMail
} from './intermediateMailCatchEventUtils'
*/
export default {
  name:"CatchEndEvent",
  components: {
    Multiselect
  },
  inheritAttrs: false,
  props: ['value','bkey', 'label', 'helper','helper2', 'requiredMessage'],
  data() {
    return {
      appid:null,
      content: false,
      loading: false,
      options: [],
      error: '',
      name: '',
      id: '',
      bc: '',
      method:false,
      methods:[],
      loading_methods: false,
      selectedMailTemplate: null,
    }
  },
  watch: {
    method: {
      immediate: true,
      handler() {
        if (this.method) {
          let idvn = this.value.split(":");
          //if (idvn.length == 1){
          idvn[1] = this.method.id;
          //}
          let cont2 = this.content;
          cont2.id = idvn[0]+":"+idvn[1];
          this.content = cont2;
          this.$emit('input', this.content.id)
          //this.value = idvn[0]+":"+idvn[1];
          var idvn1Data = idvn[1];
        }
      }
    },
    content: {
      immediate: true,
      handler() {
        if (this.content) {
          this.error = ''
          this.$emit('input', this.content.id)
          this.$axiosApi
          .$get('/data/getmailappdoc?id='+this.content.id.split(":")[0])
          .then((response) => {
            if (response.opts) {
              this.methods = response.opts
            }
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
          });

        } else if (this.requiredMessage) {
          this.error = this.requiredMessage
        } else {
        }
      }
    },
    value: {
      immediate: true,
      handler() {
        // Load selected item.
        if (this.value) {
          let idvn = this.value.split(":");
          var appid='', docid='';
          if (idvn.length == 1 && idvn[0].length != 0 ){
            appid = idvn[0];
          }
          if (idvn.length == 2 && idvn[1].length != 0 ){
            appid = idvn[0];
            docid = idvn[1];
          }

          //this.method = idvn[1]
          this.loading = true
          this.$axiosApi
            .$get('data/getappdoc?id=' + appid+'&doc='+docid)
            .then((response) => {
              response.ext.map((res)=>{
                this.method = res
              })
              this.loading = false
              response.opt.id = this.value; //response.opt.id+":"+response.ext[0].id
              // this.method = response.ext.length > 0? this.selectedMailTemplate : false//response.ext[0]:false
              this.content = response.opt
              this.methods = response.ext;
            })
            .catch((error) => {
              this.loading = false
              if (error.response.status === 404) {
                this.content = ''
                this.error = this.$t('Selected mail:doc not found')
              }
            })
            // this.emitMail();
        } else {
          this.content = ''
          if (this.requiredMessage) {
            this.error = this.requiredMessage
          } else {
            this.error = ''
          }
        }
      }
    }
  },
  computed: {

    mailId() {
      return ""//this.value ? this.value.id : ''
    },
    mailTemplate() {
      return this.value?.split(":")[1];
    },
  },
  methods: {
    async load(filter) {
      let id = this.$route.query.id
      this.$axiosApi
        .$get('/data/getmailapps?key='+this.appid+'&'+'filter='+filter)
        .then((response) => {
          if (response.opts) {
            this.options = response.opts
            // this.methods = response.opts
          }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
      
    },
    async emitMail(Id) {
      if(Id){
        this.appid = Id.id;
        this.$emit('input', Id.id)
        this.load();

        //Fetch Mailers and assign to methods
        this.$axiosApi
          .$get('/data/getmailappdoc?id='+Id.id)
          .then((response) => {
            if (response.opts) {
              this.methods = response.opts
            }
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
          });
      }

    },
    async emitMethod(method){
      if(method)
        this.selectedMailTemplate = method;
        this.$emit('select', method.type)
    }
  }
}
</script>