<template>
  <b-nav-item-dropdown right no-caret>

    <template slot="button-content">

        <!-- <a href='/'> -->

        <!-- <router-link to="/"> -->
          <!-- <nuxt-link :to="{ name: 'index' }" @click="home"> -->

            <!-- <h1>hello</h1> -->
      <img
        :src="
          user && user.pic
            ? baseURL + '/public/pp?id=' + user.pic
            : '/img/avatars/6.png'

        "
        class="img-avatar"
        alt="Profile"

        @click="home()"
      />
    <!-- </nuxt-link> -->
    <!-- </router-link> -->

      <!-- </a> -->


    </template>

    <!-- <b-dropdown-header tag="div" class="text-center">
      <strong>Account</strong>
    </b-dropdown-header>
    <b-dropdown-item>
      <font-awesome-icon :icon="['far', 'bell']" />Updates
      <b-badge variant="info">{{ itemsCount }}</b-badge>
    </b-dropdown-item>
    <b-dropdown-item>
      <font-awesome-icon :icon="['far', 'envelope']" />Messages
      <b-badge variant="success">{{ itemsCount }}</b-badge>
    </b-dropdown-item>
    <b-dropdown-item>
      <font-awesome-icon icon="tasks" />Tasks
      <b-badge variant="danger">{{ itemsCount }}</b-badge>
    </b-dropdown-item>
    <b-dropdown-item>
      <font-awesome-icon :icon="['far', 'comments']" />Comments
      <b-badge variant="warning">{{ itemsCount }}</b-badge>
    </b-dropdown-item>-->
    <b-dropdown-header tag="div" class="text-center">
      <strong>Settings</strong>
    </b-dropdown-header>
    <b-dropdown-item @click="profile">
      <font-awesome-icon :icon="['far', 'user']" /> Profile
    </b-dropdown-item>
    <b-dropdown-item v-if="user.role == 'admin'" href="/superadmin">
      <font-awesome-icon :icon="['fas', 'crown']" /> Super Admin
    </b-dropdown-item>
    <b-dropdown-item @click="switchAccount">
      <font-awesome-icon :icon="['fas', 'users']" /> Switch Accounts
    </b-dropdown-item>
    <!--<b-dropdown-item>
      <font-awesome-icon :icon="['fas', 'wrench']" />Settings
    </b-dropdown-item>
    <b-dropdown-item>
      <font-awesome-icon :icon="['fas', 'dollar-sign']" />Payments
      <b-badge variant="secondary">{{ itemsCount }}</b-badge>
    </b-dropdown-item>
    <b-dropdown-item>
      <font-awesome-icon :icon="['far', 'file']" />Projects
      <b-badge variant="primary">{{ itemsCount }}</b-badge>
    </b-dropdown-item>
    <b-dropdown-divider />
    <b-dropdown-item>
      <font-awesome-icon :icon="['fas', 'shield-alt']" />Lock Account
    </b-dropdown-item>-->
    <b-dropdown-item @click="logout">
      <font-awesome-icon :icon="['fas', 'lock']" /> Logout
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
//  console.log("Hello");


export default {
  name: "HeaderDropdown",
  data: () => {
    return {
      itemsCount: 42,
      baseURL: process.env.baseUrl,
      user: {}
    }
  },
  async mounted() {
    this.user = await this.$store.state.auth.user
  },
  methods: {
    logout() {
      try {
        this.$auth.logout().then((res) => {
          this.removeAccounts()
          this.$router.push("/login")
        })
        /* this.$store.dispatch('logout').then(() => {
          this.$router.push('/login')
        }) */
      } catch (e) {
        this.formError = e.message
      }
    },
    profile() {
      this.$router.push("/profile")
    },
    switchAccount(){
      this.$router.push("/switchaccounts")
    },
    removeAccounts(){
      var account;
      account = JSON.parse(localStorage.getItem('user'));
      var newObject={};
      for (let x in account) {
        if(account[x].active == true){
          delete account[x];
        }
      }
      localStorage.setItem('user', JSON.stringify(account));
      var account1;
      account1 = JSON.parse(localStorage.getItem('user'));
      var newObject={};
        for (let x in account1) {
          newObject[x] = account1[x];
          newObject[x].active = false
        }
        localStorage.setItem('user', JSON.stringify(newObject));
      // console.log("hello")
    },
    home(){
      this.$router.push("/")
      console.log("hello");
    }
  }
}
</script>
