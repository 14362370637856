import { ORG_NAME, ORG_ID } from '~/mixins/constants'

export default function ({ store, redirect }) {

    let orgName = localStorage.getItem('orgName')
    console.log('localStorage.getItem(orgName)', orgName);
    if (orgName == ORG_NAME) {
        return redirect('/')
    }
}
