<template>
  <div>
    <nuxt />
    <!--AppFooter /-->
  </div>
</template>

<script>
import { Footer as AppFooter } from '~/components/coreui/'

export default {
  name: 'Full',
  components: {
    AppFooter
  }
}
</script>
