import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'
import startEventConfig from '../startEvent'
import CatchEventApiSelect from '../intermediateMessageCatchEvent/CatchEventApiSelect'
import component from './messageStartEvent.vue'

export default merge(cloneDeep(startEventConfig), {
  id: 'solyd-modeler-message-start-event',
  control: false,
  component,
  label: 'API Start Event',
  definition(moddle, $t) {
    return moddle.create('bpmn:StartEvent', {
      name: $t('API Start Event'),
      eventDefinitions: [moddle.create('bpmn:MessageEventDefinition')]
    })
  },
  validateIncoming() {
    return false
  },
  inspectorData(node) {
    return Object.entries(node.definition).reduce((data, [key, value]) => {
      if (key === 'eventDefinitions') {
        data.messageName = value[0].get('messageRef').name
      } else {
        data[key] = value
      }

      return data
    }, {})
  },
  inspectorHandler(value, node, setNodeProp, moddle) {
    for (const key in omit(value, [
      '$type',
      'eventDefinitions',
      'messageName'])) {
      if (node.definition[key] === value[key]) {
        continue
      }

      setNodeProp(node, key, value[key])
    }

    /*
    if (
      node.definition.eventDefinitions[0].get('messageRef') !== value.messageRef
    ) {
      setNodeProp(node, 'eventDefinitions', [
        moddle.create('bpmn:MessageEventDefinition', {
          messageRef: value.messageRef
        })
      ])
    }
    */
    const message = node.definition.get('eventDefinitions')[0].messageRef
    if (message.name !== value.messageName) {
      message.name = value.messageName
    }

  },
  inspectorConfig: [
    {
      items: [
        {
          items: [
            {},
            {
              component: CatchEventApiSelect,
              config: {
                label: 'Listen to API',
                name: 'messageName',
                helper:
                  'Select from which Intermediate Message Throw or Message End event to listen'
              }
            }
          ]
        }
      ]
    }
  ]
})
