import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'
import startEventConfig from '../startEvent'
import CatchEventBlockchainSelect from '../intermediateBlockchainCatchEvent/CatchEventBlockchainSelect'
import component from './blockchainStartEvent.vue'


export default merge(cloneDeep(startEventConfig), {
  id: 'solyd-modeler-blockchain-start-event',
  control: false,
  component,
  label: 'Blockchain Start Event',
  definition(moddle, $t) {
    return moddle.create('bpmn:StartEvent', {
      name: $t('Blockchain Start Event'),
      eventDefinitions: [moddle.create('bpmn:SignalEventDefinition')]
    })
  },
  validateIncoming() {
    return false
  },
  inspectorData(node) {
    return Object.entries(node.definition).reduce((data, [key, value]) => {
      if (key === 'eventDefinitions') {
        data.BlockchainRef = value[0].get('blockchainRef')
      } else {
        data[key] = value
      }
      return data
    }, {})
  },
  inspectorHandler(value, node, setNodeProp, moddle) {
    for (const key in omit(value, ['$type', 'BlockchainRef'])) {

      if (node.definition[key] === value[key]) {
        continue
      }

      setNodeProp(node, key, value[key])
    }

    if (
      node.definition.eventDefinitions[0].get('BlockchainRef') !== value.blockchainRef
    ) {
      //console.log("in47: ", value.blockchainRef)
      setNodeProp(node, 'eventDefinitions', [
        moddle.create('bpmn:SignalEventDefinition', {
          blockchainRef: value.blockchainRef
        })
      ])
    }
  },
  inspectorConfig: [
    {
      items: [
        {
          items: [
            {},
            {
              component: CatchEventBlockchainSelect,
              config: {
                label: 'Select Blockchain Container',
                name: 'blockchainRef',
                helper:
                  'Select from which Blockchain to listen',
                  helper2: 'Select trigger event'
              }
            }
          ]
        }
      ]
    }
  ]
})
