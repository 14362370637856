var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-col',{staticClass:"h-100 overflow-hidden controls-column",class:[
    {
      'ignore-pointer': _vm.canvasDragPosition,
      'controls-column-compressed': _vm.compressed
    }
  ],attrs:{"data-test":"controls-column"}},[_c('b-card',{staticClass:"controls rounded-0 border-top-0 border-bottom-0 border-left-0",style:({ height: _vm.parentHeight }),attrs:{"no-body":""}},[_c('b-list-group',{staticClass:"overflow-auto w-auto",class:{ 'd-flex align-items-center': _vm.compressed },attrs:{"flush":""}},_vm._l((_vm.controls),function(control,index){return _c('b-list-group-item',{key:index,staticClass:"control-item border-right-0 flex-grow-1",class:_vm.compressed
            ? 'p-0 pt-2 pb-2 w-100 d-flex justify-content-center'
            : 'p-2',attrs:{"data-test":control.type},on:{"dragstart":function($event){return $event.preventDefault()},"mousedown":function($event){return _vm.startDrag($event, control)}}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.viewport.d50",value:({
            customClass: 'no-pointer-events'
          }),expression:"{\n            customClass: 'no-pointer-events'\n          }",modifiers:{"hover":true,"viewport":true,"d50":true}}],staticClass:"tool",class:{ 'text-truncate ml-1': !_vm.compressed },attrs:{"title":_vm.$t(control.label)}},[_c('img',{staticClass:"tool-icon",attrs:{"src":control.icon,"alt":_vm.$t(control.label)}}),_vm._v(" "),(!_vm.compressed)?_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t(control.label)))]):_vm._e()])])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }