function isBlockchainElement(element) {
  return element.$type === 'bpmn:Blockchain'
}

function toDropdownFormat(element) {
  return {
    value: element.get('id'),
    content: element.get('name')
  }
}

export function getBlockchainsList(api) {
  /*return store.getters.rootElements
    .filter(isBlockchainElement)
    .map(toDropdownFormat)
    */
     return  api
      .$get('data/getbcs')
      .then((response) => {
        //console.log("I20: ",response)
         return  response.opts //.map(toDropdownFormat)
      });
}

export function getBlockchain(api, id) {
  //return store.getters.rootElements.find((element) => element.get('id') === id)
  return  api
   .$get('data/getbc?type=methods&id='+id)
   .then((response) => {
     //console.log("I20: ",response)
     response.opt.id = response.opt.id+":io"
      return  response.opt
   });
}
