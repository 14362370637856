export default {
  items: [
    {
      name: 'Home',
      url: '/',
      icon: 'icon-home',
      badge: {
        variant: 'primary',
         text: 'Back'
      }
    },
    {
      title: true,
      name: 'Profile Management',
      class: '',
      wrapper: {
        element: '',
        attributes: {}
      }
    },
    {
      name: 'Profile',
      url: '/profile/',
      icon: 'icon-shield',
      badge: {
        variant: 'primary'
        // text: 'NEW'
      }
    },
    {
      name: 'Licenses',
      url: '/profile/license',
      icon: 'icon-lock',
      badge: {
        variant: 'primary'
        // text: 'NEW'
      }
    },
    {
      name: 'Payments',
      url: '/profile/payments',
      icon: 'icon-wallet',
      badge: {
        variant: 'primary'
        // text: 'NEW'
      }
    },
    /*{
      name: 'Contracts',
      url: '/profile/contract',
      icon: 'icon-doc',
      badge: {
        variant: 'primary'
        // text: 'NEW'
      }
    },*/
  ]
}
