import Vue from 'vue'
import moment from 'moment'

Vue.filter('proc', function(value, formatString) {
  if (value !== undefined) return ''

  if (formatString !== undefined) {
    return moment(value).format(formatString)
  }

  return moment(value).format('DD/MM/YYYY')
})
