const interruptingToggleConfig = {
  component: 'FormCheckbox',
  config: {
    label: 'Interrupting',
    name: 'cancelActivity',
    helper:
      'Select to interrupt the current Request workflow and route to the alternate workflow, thereby preventing parallel workflow'
  }
}

export default interruptingToggleConfig
