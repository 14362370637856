function isMessageElement(element) {
  return element.$type === 'bpmn:Message'
}

function toDropdownFormat(element) {
  return {
    value: element.get('id'),
    content: element.get('name')
  }
}

export function getMessagesList(store) {
  return store.getters.rootElements
    .filter(isMessageElement)
    .map(toDropdownFormat)
}

export function getMessage(store, id) {
  return store.getters.rootElements.find((element) => element.get('id') === id)
}

export function getApiList(call) {
  return call.$get('/data/getextapis')
  .then((response) => {
     return  response.opts.rows
  });
}

export function getApi(call, id) {
  return call.$get('/data/getextapi?id='+id)
   .then((response) => {
      return  response.opt
   });
}

export function getHookList(call) {
  return call.$get('/data/getexthooks')
  .then((response) => {
     return  response.opts.rows
  });
}

export function getHook(call, id) {
  return call.$get('/data/getexthook?id='+id)
   .then((response) => {
      return  response.opt
   });
}
