<template>
  <div>
    <!-- <div class="sidebar-header"></div> -->
  </div>
</template>
<script>
export default {
  name: 'SidebarHeader'
}
</script>
