<template>
  <div>
    <div class="form-group">
      <div class="notification-settings-group">
        <div class="notification-settings-header">{{ $t('Requester') }}</div>
        <div class="custom-control custom-switch">
          <input
            id="notify-requester-assigned"
            v-model="requesterAssigned"
            type="checkbox"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="notify-requester-assigned">{{
            $t('Assigned')
          }}</label>
        </div>
        <div class="custom-control custom-switch">
          <input
            id="notify-requester-completed"
            v-model="requesterCompleted"
            type="checkbox"
            class="custom-control-input"
          />
          <label
            class="custom-control-label"
            for="notify-requester-completed"
            >{{ $t('Completed') }}</label
          >
        </div>
        <div class="custom-control custom-switch">
          <input
            id="notify-requester-due"
            v-model="requesterDue"
            type="checkbox"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="notify-requester-due">{{
            $t('Due')
          }}</label>
        </div>
      </div>
      <div class="notification-settings-group">
        <div class="notification-settings-header">{{ $t('Assignee') }}</div>
        <div class="custom-control custom-switch">
          <input
            id="notify-assignee-assigned"
            v-model="assigneeAssigned"
            type="checkbox"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="notify-assignee-assigned">{{
            $t('Assigned')
          }}</label>
        </div>
        <div class="custom-control custom-switch">
          <input
            id="notify-assignee-completed"
            v-model="assigneeCompleted"
            type="checkbox"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="notify-assignee-completed">{{
            $t('Completed')
          }}</label>
        </div>
        <div class="custom-control custom-switch">
          <input
            id="notify-assignee-due"
            v-model="assigneeDue"
            type="checkbox"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="notify-assignee-due">{{
            $t('Due')
          }}</label>
        </div>
      </div>
      <div class="notification-settings-group">
        <div class="notification-settings-header">{{ $t('Participants') }}</div>
        <div class="custom-control custom-switch">
          <input
            id="notify-participants-assigned"
            v-model="participantsAssigned"
            type="checkbox"
            class="custom-control-input"
          />
          <label
            class="custom-control-label"
            for="notify-participants-assigned"
            >{{ $t('Assigned') }}</label
          >
        </div>
        <div class="custom-control custom-switch">
          <input
            id="notify-participants-completed"
            v-model="participantsCompleted"
            type="checkbox"
            class="custom-control-input"
          />
          <label
            class="custom-control-label"
            for="notify-participants-completed"
            >{{ $t('Completed') }}</label
          >
        </div>
        <div class="custom-control custom-switch">
          <input
            id="notify-participants-due"
            v-model="participantsDue"
            type="checkbox"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="notify-participants-due">{{
            $t('Due')
          }}</label>
        </div>
      </div>

      <!-- <table id="table-notifications" class="table">
                <thead>
                    <tr>
                        <th class="notify"></th>
                        <th class="action">Assigned</th>
                        <th class="action">Completed</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="notify">Requester</td>
                        <td class="action">
                            <div class="custom-control custom-switch">
                                <input v-model="notifications.requester.assigned" type="checkbox" class="custom-control-input" id="notify-requester-assigned">
                                <label class="custom-control-label" for="notify-requester-assigned"></label>
                            </div>
                        </td>
                        <td class="action">
                            <div class="custom-control custom-switch">
                                <input v-model="notifications.requester.completed"  type="checkbox" class="custom-control-input" id="notify-requester-completed">
                                <label class="custom-control-label" for="notify-requester-completed"></label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="notify">Assignee</td>
                        <td class="action">
                            <div class="custom-control custom-switch">
                                <input v-model="notifications.assignee.assigned"  type="checkbox" class="custom-control-input" id="notify-assignee-assigned">
                                <label class="custom-control-label" for="notify-assignee-assigned"></label>
                            </div>
                        </td>
                        <td class="action">
                            <div class="custom-control custom-switch">
                                <input v-model="notifications.assignee.completed"  type="checkbox" class="custom-control-input" id="notify-assignee-completed">
                                <label class="custom-control-label" for="notify-assignee-completed"></label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="notify">Participants</td>
                        <td class="action">
                            <div class="custom-control custom-switch">
                                <input v-model="notifications.participants.assigned"  type="checkbox" class="custom-control-input" id="notify-participants-assigned">
                                <label class="custom-control-label" for="notify-participants-assigned"></label>
                            </div>
                        </td>
                        <td class="action">
                            <div class="custom-control custom-switch">
                                <input v-model="notifications.participants.completed"  type="checkbox" class="custom-control-input" id="notify-participants-completed">
                                <label class="custom-control-label" for="notify-participants-completed"></label>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table> -->
    </div>
  </div>
</template>

<script>
import highlightedNode from '~/mixins/highlightedNode'

function NotificationTemplate() {
  this.requester = {
    assigned: false,
    completed: false
  }
  this.assignee = {
    assigned: false,
    completed: false
  }
  this.participants = {
    assigned: false,
    completed: false
  }
}

export default {
  mixins: [highlightedNode],
  props: ['value', 'label', 'helper', 'property'],
  data() {
    return {
      content: '',
      users: [],
      requesterAssigned: false,
      requesterCompleted: false,
      requesterDue: false,
      assigneeAssigned: false,
      assigneeCompleted: false,
      assigneeDue: false,
      participantsAssigned: false,
      participantsCompleted: false,
      participantsDue: false
    }
  },
  computed: {
    process() {
      return this.highlightedNode
    },
    modelerId() {
      return this.highlightedNode._modelerId
    },
    node() {
      return this.highlightedNode
    },
    nodeId() {
      return this.node.definition.id
    },
    notifications() {
      if (this.node.notifications === undefined) {
        if (
          _.get(
            this.process,
            `task_notifications.${this.nodeId}`,
            undefined
          ) === undefined
        ) {
          this.node.notifications = new NotificationTemplate()
        } else {
          this.node.notifications = this.process.task_notifications[this.nodeId]
        }
      }
      return this.node.notifications
    }
  },
  watch: {
    requesterAssigned(value) {
      this.notifications.requester.assigned = value
    },
    requesterCompleted(value) {
      this.notifications.requester.completed = value
    },
    requesterDue(value) {
      this.notifications.requester.due = value
    },
    assigneeAssigned(value) {
      this.notifications.assignee.assigned = value
    },
    assigneeCompleted(value) {
      this.notifications.assignee.completed = value
    },
    assigneeDue(value) {
      this.notifications.assignee.due = value
    },
    participantsAssigned(value) {
      this.notifications.participants.assigned = value
    },
    participantsCompleted(value) {
      this.notifications.participants.completed = value
    },
    participantsDue(value) {
      this.notifications.participants.due = value
    },
    modelerId(newValue, oldValue) {
      this.loadNotifications()
    }
  },
  mounted() {
    this.loadNotifications()
  },
  methods: {
    loadNotifications() {
      this.requesterAssigned = this.notifications.requester.assigned
      this.requesterCompleted = this.notifications.requester.completed
      this.requesterDue = this.notifications.requester.due
      this.assigneeAssigned = this.notifications.assignee.assigned
      this.assigneeCompleted = this.notifications.assignee.completed
      this.assigneeDue = this.notifications.assignee.due
      this.participantsAssigned = this.notifications.participants.assigned
      this.participantsCompleted = this.notifications.participants.completed
      this.participantsDue = this.notifications.participants.due
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-settings-group {
  margin-bottom: 10px;
}

.notification-settings-header {
  font-weight: bold;
}

.custom-control-label {
  margin-bottom: 0;
  padding-top: 3px;
}

.form-group {
  padding: 0px;
}
</style>
