<template>
  <footer class="app-footer">
    <span> <a href="https://FastBuilder.AI">Home </a> &copy; 2021-2022, All Rights Reserved.  Patents Pending  &nbsp; | &nbsp;  </span>
    <span> <a href="https://www.FastBuilder.AI/policy/tnc"> Terms & Conditions </a> &nbsp;  | &nbsp;  </span>
    <span> <a href="https://www.FastBuilder.AI/policy/privacy"> Privacy policy </a> &nbsp;  | &nbsp;  </span>
    <span> <a href="https://www.FastBuilder.AI/enterprise/lowcode/company-aboutus/" target="_blank"> About Us </a>  &nbsp;   &nbsp;   </span>


    <span class="ml-auto"
      > Powered by <a href="https://www.FastBuilder.AI/"> FastBuilder.AI, Inc.</a> </span
    >

  </footer>
</template>
<script>
export default {
  name: 'CFooter'
}
</script>
