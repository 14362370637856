<template>
  <div class="form-group">
    <label>{{ $t(label) }}</label>
    <multiselect
      v-model="content"
      track-by="id"
      label="title"
      :class="{ 'border border-danger': error }"
      :loading="loading"
      :placeholder="$t('type here to search')"
      :options="scripts"
      :multiple="false"
      :show-labels="false"
      :searchable="true"
      :internal-search="false"
      @open="load"
      @search-change="load"
    >
      <template slot="noResult">
        {{ $t('No elements found. Consider changing the search query.') }}
      </template>
      <template slot="noOptions">
        {{ $t('No Data Available') }}
      </template>
    </multiselect>
    <small v-if="error" class="text-danger">{{ error }}</small>
    <small v-if="helper" class="form-text text-muted">{{ $t(helper) }}</small>
    <a
      v-if="content != null"
      :href="`/script/editor/?id=${content.id}`"
      target="_blank"
    >
      {{ $t('Open Script') }}
      <i class="ml-1 fas fa-external-link-alt" />
    </a>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  props: ['value', 'label', 'helper', 'params'],
  data() {
    return {
      content: '',
      loading: false,
      scripts: [],
      error: ''
    }
  },
  watch: {
    content: {
      handler() {
        console.log(this.content)
        if(this.content != null){
          this.$emit('input', this.content.id)
        }
      }
    },
    value: {
      immediate: true,
      handler() {
        // Load selected item.
        if (this.value) {
          this.loading = true
          this.$axiosApi
            .$get('data/getscript?id=' + this.value)
            .then((response) => {
              this.loading = false
              this.content = response.opt
            })
            .catch((error) => {
              this.loading = false
              if (error.response.status === 404) {
                this.content = ''
                this.error = this.$t('Selected script not found')
              }
            })
        } else {
          this.content = ''
          this.error = ''
        }
      }
    }
  },
  methods: {
    load(filter) {
      this.loading = true
      this.$axiosApi
        .$get(
          'data/getscripts?order_direction=asc' +
            (typeof filter === 'string' ? '&filter=' + filter : '')
        )
        .then((response) => {
          this.loading = false
          this.scripts = response.opts.rows
        })
        .catch((err) => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'vue-multiselect/dist/vue-multiselect.min.css';
</style>
