<template>
  <div class="form-group">
    <div>
      <label>{{ $t(label) }}</label>
      <button type="button" class="btn-sm float-right" @click="expandEditor">
        <i class="fas fa-expand"></i>
      </button>
    </div>
    <div class="small-editor-container">
      <codemirror v-model="tempCode" :options="cmOptions" class="editor" />
    </div>
    <small class="form-text text-muted">{{ $t(helper) }}</small>
    <b-modal
      v-cloak
      v-model="showPopup"
      size="lg"
      centered
      :title="$t('Script Config Editor')"
    >
      <div class="editor-container">
        <codemirror
          ref="largeCm"
          v-model="tempCode"
          :options="cmOptions"
          class="editor"
        />
      </div>
      <div slot="modal-footer">
        <b-button class="btn btn-secondary" @click="closePopup">
          {{ $t('CLOSE') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/theme/base16-light.css'
import 'codemirror/lib/codemirror.css'
import highlightedNode from '~/mixins/highlightedNode'

export default {
  components: {
    codemirror
  },
  mixins: [highlightedNode],
  props: ['value', 'label', 'helper', 'property'],
  data() {
    return {
      tempCode: '',
      cmOptions: {
        tabSize: 2,
        mode: {
          name: 'javascript',
          json: true
        },
        theme: 'base16-light',
        lineNumbers: true,
        line: true,
        viewportMargin: Infinity,
        autofocus: true,
        autoRefresh: true
      },
      showPopup: false
    }
  },
  computed: {
    code() {
      return _.get(this.highlightedNodeDefinition, this.property, '')
    }
  },
  watch: {
    code(newVal) {
      this.tempCode = this.code
    },
    tempCode(newVal) {
      _.set(this.highlightedNodeDefinition, this.property, newVal)
      this.$emit('input', this.value)
    }
  },
  methods: {
    /**
     * Open a popup editor
     *
     */
    expandEditor() {
      this.showPopup = true
      const that = this
      setTimeout(function() {
        that.$refs.largeCm.refresh()
      }, 1)
    },
    /**
     * Close the popup editor
     *
     */
    closePopup() {
      this.showPopup = false
    }
  }
}
</script>

<style lang="scss" scoped>
.CodeMirror {
  border: 1px solid #eee;
  height: 100%;
}

.small-editor-container {
  margin-top: 1em;
}
.small-editor-container .editor {
  width: 100%;
  height: 12em;
}
.editor-container .editor {
  height: 60vh;
}

.CodeMirror {
  border: 1px solid #eee;
  width: 100%;
  height: auto;
}
</style>
